import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

var firebaseConfig = {
  apiKey: "AIzaSyDsX1A7RxLxOCbZGoMhGGBlWk3BXCyXUFQ",
  authDomain: "wonderquiz-6ca2a.firebaseapp.com",
  databaseURL: "https://wonderquiz-6ca2a.firebaseio.com",
  projectId: "wonderquiz-6ca2a",
  storageBucket: "wonderquiz-6ca2a.appspot.com",
  messagingSenderId: "504456262673",
  appId: "1:504456262673:web:62195217a5849687fe6db0",
  measurementId: "G-78GGZ8VKLS"
};
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const bucket = firebase.storage();

export const firestore = firebase.firestore();
export { firebase };
