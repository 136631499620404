import React from "react";
import { Field, useField } from "formik";
import { useParams } from "react-router-dom";
import { Copy } from "../../images/";
import { useAuth } from "../../util/authContext";
import { fontSchemes, colors } from "../../util/templates";
import { CirclePicker } from "react-color";
export default function Settings({ deleteQuiz, quiz }) {
  const { user } = useAuth();
  const [fontField] = useField("fontScheme");
  const { id } = useParams();
  const urlParam = encodeURI(btoa(`${user.uid}-${id}`));
  let quizUrl = `https://quiz.wonderquiz.app/${urlParam}`;
  if (quiz.link) {
    quizUrl = `https://quiz.wonderquiz.app/q/${quiz.link}`;
  }
  function copyUrl() {
    var copyText = document.getElementById("quizUrl");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  }

  const FontPreview = ({ fontValue }) => {
    const currentFont = fontSchemes.find(scheme => {
      return scheme.value === fontValue;
    });
    return currentFont ? (
      <div className="font-preview">
        <label>Preview:</label>
        <img src={currentFont.image} alt="preview" />
      </div>
    ) : (
      ""
    );
  };
  return (
    <React.Fragment>
      <header>
        <h1>Quiz settings</h1>
      </header>
      <div className="box p3">
        <h3>Global Settings</h3>
        <div className="formgrid">
          <div className="formgroup span2">
            <label htmlFor="name">Name of the quiz</label>
            <Field id="name" className="big" name="name" type="text" />
          </div>
          <div className="formgroup">
            <label htmlFor="main-color">Main color</label>
            <Field id="main-color" name="mainColor">
              {({
                field, // { name, value, onChange, onBlur }
                form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                meta
              }) => {
                return (
                  <CirclePicker
                    colors={colors}
                    color={field.value}
                    onChangeComplete={color =>
                      form.setFieldValue("mainColor", color.hex)
                    }
                  />
                );
              }}
            </Field>
          </div>
          <div className="formgroup">
            <label htmlFor="font-scheme">Font scheme</label>
            <Field id="font-scheme" name="fontScheme" as="select">
              {fontSchemes &&
                fontSchemes.map((scheme, idx) => {
                  return (
                    <option key={"scheme" + idx} value={scheme.value}>
                      {scheme.name}
                    </option>
                  );
                })}
            </Field>
            <FontPreview fontValue={fontField.value} />
          </div>
        </div>
      </div>
      {id !== "create" && (
        <React.Fragment>
          <div className="box p3">
            <h3>Sharing</h3>
            <label>Share URL:</label>
            <div className="copyUrl">
              <a href={quizUrl} target="_blank" rel="noopener noreferrer">
                {quizUrl}
              </a>
              <Copy color={"#CCC"} onClick={copyUrl} />
            </div>
            <input
              type="text"
              onChange={e => e.preventDefault()}
              value={quizUrl}
              id="quizUrl"
            />
          </div>
          <div className="box p3" style={{ border: "1px solid red" }}>
            <h3>Danger Zone</h3>
            <div className="delete-quiz">
              <button
                type="button"
                onClick={() => deleteQuiz(id)}
                className="button button-danger"
                style={{ float: "right", color: "#cb2431" }}
              >
                Delete quiz
              </button>
              <strong>Delete this quiz</strong>
              <p>
                Once you delete a quiz, there is no going back. Please be
                certain.
              </p>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
