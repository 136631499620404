import React from "react";
import "./StartHeader.scss";
import { NavLink, Link } from "react-router-dom";
import { Logo } from "../../images";
import { useTranslation } from "react-i18next";

export default function StartHeader() {
  const { t } = useTranslation();

  return (
    <div className="StartHeader">
      <Link className="logo-wrap" to="/">
        <Logo color={"#038d92"} />
      </Link>
      <nav className="menu">
        <ul>
          <li>
            <NavLink className="button-transparent" exact to="/login">
              {t("header.login")}
            </NavLink>
          </li>
          <li>
            <NavLink className="button button-primary" exact to="/signup">
              {t("header.signup")}
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}
