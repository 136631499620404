import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { bucket } from "../../util/firebase";
import { useField, Field } from "formik";
import FileUploader from "react-firebase-file-uploader";
import { useAuth } from "../../util/authContext";
import { Upload, Trash } from "../../images";

export default function Start() {
  const [progress, setProgress] = useState(0);
  const uploadRef = useRef(null);

  const { user } = useAuth();
  const [field, meta, helpers] = useField("start.logo");
  const { id } = useParams();
  const bucketRef = bucket.ref(`${user.uid}/${id}`);
  const updateLogo = response => {
    setProgress(0);
    bucket
      .ref(`${user.uid}/${id}/${response}`)
      .getDownloadURL()
      .then(url => {
        helpers.setValue(url);
      });
  };
  return (
    <div>
      <header>
        <h1>Start screen</h1>
      </header>
      <div className="box p3">
        <h3>Edit start screen</h3>

        <div className="formgrid">
          <div className="formgroup span2">
            <label htmlFor="start.title">Welcome title</label>
            <Field
              id="start.title"
              className="big"
              name="start.title"
              type="text"
            />
          </div>
          <div className="formgroup">
            <label htmlFor="start.text">Welcome text</label>
            <Field
              rows="6"
              id="start.text"
              name="start.text"
              component="textarea"
            />
          </div>
          <div className="formgroup" style={{ gridRow: "span 2" }}>
            <label htmlFor="start.logo">Start Logo</label>

            <label
              className="uploader"
              style={{ backgroundImage: `url(${field.value})` }}
            >
              {!field.value && (
                <div className="image-placerholder">
                  <Upload color={"#CCC"}></Upload> Upload your image
                </div>
              )}
              {field.value && (
                <div
                  className="trash-image"
                  onClick={() => helpers.setValue("")}
                >
                  <Trash />
                </div>
              )}
              <FileUploader
                hidden
                accept="image/*"
                name="avatar"
                randomizeFilename
                ref={uploadRef}
                className={field.value ? `uploaded` : "none"}
                storageRef={bucketRef}
                onUploadError={() => alert("There was an error.")}
                onUploadSuccess={updateLogo}
                onProgress={progress => setProgress(progress)}
              />
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </label>
            <Field id="start.logo" name="start.logo" type="hidden" />
          </div>
        </div>
      </div>
    </div>
  );
}
