import React from 'react';

const Menu = ({ color }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="18"
		viewBox="0 0 24 18"
	>
		<defs></defs>
		<g transform="translate(-4 -7)">
			<path fill={color} d="M4,7V9H28V7Zm0,8v2H28V15Zm0,8v2H28V23Z" />
		</g>
	</svg>
);

Menu.defaultProps = {
	color: '#FFF',
};

export default Menu;
