import React, { useState, useContext, useEffect } from 'react';
import { firebase, auth } from './firebase';

export const AuthContext = React.createContext();
export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({ children, ...initOptions }) => {
	const [authError, setAuthError] = useState(null);
	const [success, setSuccess] = useState(null);

	const [user, setUser] = useState();
	useEffect(() => {
		auth.onAuthStateChanged((user) => {
			setUser(user);
		});
	}, []);

	const signIn = ({ email, password }) => {
		setAuthError(null);
		auth
			.signInWithEmailAndPassword(email, password)
			.then((user) => {})
			.catch((error) => {
				setAuthError('Error signing in with password and email!');
			});
	};

	const signOut = (e) => {
		e.preventDefault();
		auth.signOut();
	};

	const signInWithGoogle = () => {
		auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
	};

	const signInWithFacebook = () => {
		auth.signInWithPopup(new firebase.auth.FacebookAuthProvider());
	};

	const signUp = ({ email, password, repeat }) => {
		setAuthError(null);
		if (password !== repeat) {
			return setAuthError('Passwords do not match.');
		}
		auth
			.createUserWithEmailAndPassword(email, password)
			.then((userCredential) => {
				userCredential.user.sendEmailVerification();
			})
			.catch((error) => {
				setAuthError(error.message);
			});
	};

	const changePassword = ({ password, repeat }) => {
		setAuthError(null);
		if (password !== repeat) {
			return setAuthError('Passwords do not match.');
		}
		auth.currentUser
			.updatePassword(password)
			.then(function () {
				setSuccess('Password changed.');
			})
			.catch(function (error) {
				setAuthError(error.message);
			});
	};

	const resetPassword = ({ email }) => {
		auth
			.sendPasswordResetEmail(email)
			.then(function () {
				setSuccess('The E-Mail for resetting your password was sent.');
			})
			.catch(function (error) {
				setAuthError(error.message);
			});
	};

	const deleteUser = () => {
		var user = auth.currentUser;
		user
			.delete()
			.then(function () {})
			.catch(function (error) {});
	};

	const exports = {
		user,
		signUp,
		signIn,
		signInWithFacebook,
		signInWithGoogle,
		signOut,
		authError,
		setAuthError,
		success,
		resetPassword,
		changePassword,
		deleteUser,
	};

	return (
		<AuthContext.Provider value={exports}>{children}</AuthContext.Provider>
	);
};
