import React from "react";

const Eye = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.688"
    height="16"
    viewBox="0 0 30.688 16"
  >
    <g transform="translate(-0.656 -8)">
      <path
        fill={color}
        d="M16,8C7.664,8,1.25,15.344,1.25,15.344L.656,16l.594.656S7.1,23.324,14.875,23.938a10.156,10.156,0,0,0,2.25,0c7.777-.613,13.625-7.281,13.625-7.281L31.344,16l-.594-.656S24.336,8,16,8Zm0,2a14.485,14.485,0,0,1,6,1.406A6.891,6.891,0,0,1,23,15a7,7,0,0,1-6.219,6.969c-.02,0-.043,0-.062,0C16.48,21.98,16.242,22,16,22c-.266,0-.523-.016-.781-.031a7.019,7.019,0,0,1-5.25-10.531H9.938A14.52,14.52,0,0,1,16,10Zm0,2a3,3,0,1,0,3,3A3,3,0,0,0,16,12Zm-8.75.938a8.891,8.891,0,0,0,1.125,6.844A23.2,23.2,0,0,1,3.531,16,23.931,23.931,0,0,1,7.25,12.938Zm17.5,0A23.931,23.931,0,0,1,28.469,16a23.2,23.2,0,0,1-4.844,3.781,8.891,8.891,0,0,0,1.125-6.844Z"
      />
    </g>
  </svg>
);

Eye.defaultProps = {
  color: "#FFF"
};

export default Eye;
