import React from "react";
import { Formik, Field, Form } from "formik";
import { useAuth } from "../../util/authContext";
import "./Login.scss";
import { useTranslation } from "react-i18next";

export default function Forgot() {
  const { resetPassword, authError, success } = useAuth();
  const { t } = useTranslation();

  return (
    <div id="Login">
      <h1>{t("forgot.headline")}</h1>
      {!success ? (
        <Formik
          initialValues={{ email: "" }}
          onSubmit={(values) => resetPassword(values)}
        >
          <Form>
            <div className="formgroup">
              <div className="mb2">{t("forgot.message")}</div>
              <label>E-Mail</label>
              <Field type="email" name="email" />
            </div>
            <input
              type="submit"
              className="button button-primary"
              value={t("forgot.send")}
            />
            {authError && <div className="error">{authError}</div>}
          </Form>
        </Formik>
      ) : (
        <div>{success}</div>
      )}
    </div>
  );
}
