import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { en, de } from "../languages";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  de: {
    translation: de,
  },
  en: {
    translation: en,
  },
};

const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};

i18next
  .use(initReactI18next) // passes i18n down to react-i18next

  .use(LanguageDetector)

  .init({
    detection: options,

    resources,
    whitelist: [
      "es",
      "en",
      "da",
      "de",
      "el",
      "fi",
      "fil",
      "tl",
      "fr",
      "hi",
      "id",
      "it",
      "ja",
    ],
    debug: true,
    fallbackLng: "en",
    keySeparator: ".", // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      wait: true,
    },
  });

export default i18next;
