import React, { useEffect, useState } from 'react';

export default function SaveButton({ state }) {
	const [savingState, setSavingState] = useState(state);
	useEffect(() => {
		setSavingState(state);
	}, [state]);
	return (
		<button type="submit" className="button-save button button-primary">
			<div
				className="icon"
				style={{ marginRight: '0.7rem', marginBottom: '-0.1rem' }}
			>
				{state.status === 'saved' ? <Check /> : null}
				{state.status === 'save' ? <Disk /> : null}
				{state.status === 'saving' ? <Rotate /> : null}
			</div>
			{savingState.label}
		</button>
	);
}

const Check = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="16"
			viewBox="0 0 27.438 20.125"
		>
			<defs></defs>
			<g transform="translate(-2.281 -6.281)">
				<path
					fill="#FFF"
					d="M28.281,6.281,11,23.563,3.719,16.281,2.281,17.719l8,8,.719.688.719-.687,18-18Z"
				/>
			</g>
		</svg>
	);
};

const Rotate = () => {
	return (
		<svg
			className="rotate-icon"
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="17"
			viewBox="0 0 23.5 24.75"
		>
			<defs></defs>
			<g transform="translate(-4.75 -4.25)">
				<path
					fill={'#FFF'}
					d="M16.031,4.25A1.75,1.75,0,1,0,17.781,6,1.752,1.752,0,0,0,16.031,4.25Zm7.063,2.719a2,2,0,1,0,2,2A2,2,0,0,0,23.094,6.969ZM8.969,7.406a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,8.969,7.406ZM26,13.781a2.25,2.25,0,1,0,2.25,2.25A2.251,2.251,0,0,0,26,13.781ZM6,14.719a1.25,1.25,0,1,0,1.25,1.25A1.249,1.249,0,0,0,6,14.719Zm17.031,5.875a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,23.031,20.594ZM8.906,22.031a1,1,0,1,0,1,1A1,1,0,0,0,8.906,22.031ZM15.969,23a3,3,0,1,0,3,3A3,3,0,0,0,15.969,23Z"
				></path>
			</g>
		</svg>
	);
};

const Disk = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 22 22"
		>
			<defs></defs>
			<g transform="translate(-5 -5)">
				<path
					fill="#FFF"
					d="M5,5V27H27V9.594l-.281-.312-4-4L22.406,5ZM7,7h3v6H22V7.438l3,3V25H23V16H9v9H7Zm5,0h4V9h2V7h2v4H12ZM11,18H21v7H11Z"
				/>
			</g>
		</svg>
	);
};
