import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Menu, Close, Trash, Move } from "../../images";
import { useField } from "formik";

export default function Toolbar() {
  const [questionField, meta, questionHelpers] = useField("questions");
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const addQuestion = e => {
    e.preventDefault();
    const rnd = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5);
    const rnd2 = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5);
    let questionArray = [...questionField.value];
    if (!questionArray) questionArray = [];
    questionArray.push({
      id: rnd,
      question: "",
      explanation: "",
      image: "",
      correct_answer: rnd2,
      answers: [
        {
          id: rnd2,
          text: "First answer"
        }
      ]
    });
    questionHelpers.setValue(questionArray);
  };
  const moveQuestion = data => {
    const questions = [...questionField.value];
    const [removed] = questions.splice(data.source.index, 1);
    questions.splice(data.destination.index, 0, removed);
    questionHelpers.setValue(questions);
  };

  const deleteQuestion = questionId => {
    const questions = [...questionField.value];
    const questionIndex = questions.findIndex(
      question => question.id === questionId
    );
    questions.splice(questionIndex, 1);
    questionHelpers.setValue(questions);
  };
  return (
    <React.Fragment>
      <button
        className="button-transparent show-toolbar"
        type="button"
        onClick={() => setVisible(true)}
      >
        <Menu color="#8c8f95" />
      </button>
      <div className={`Toolbar ${visible && "visible"}`}>
        <button
          className=" button-transparent hide-toolbar"
          type="button"
          onClick={() => setVisible(false)}
        >
          <Close color="#8c8f95" />
        </button>
        <div className="subheader">Contents</div>
        <ul>
          <li>
            <NavLink
              onClick={() => setVisible(false)}
              to={`/edit/${id}/settings`}
            >
              Quiz settings
            </NavLink>
          </li>
          <li>
            <NavLink onClick={() => setVisible(false)} to={`/edit/${id}/start`}>
              Start screen
            </NavLink>
          </li>
          <li>
            <DragDropContext
              onDragEnd={data => {
                if (data.destination && data.source) {
                  moveQuestion(data);
                }
              }}
            >
              <Droppable droppableId="droppable-toolbar">
                {(provided, snapshot) => (
                  <ul ref={provided.innerRef} {...provided.droppableProps}>
                    <label style={{ textTransform: "uppercase" }}>
                      Questions
                    </label>
                    {questionField.value &&
                      questionField.value.map((question, idx) => (
                        <Draggable
                          key={question.id}
                          draggableId={`question-${question.id}`}
                          index={idx}
                        >
                          {(provided, snapshot) => (
                            <li
                              key={question.id}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <button
                                className="quest-move"
                                {...provided.dragHandleProps}
                              >
                                <Move color={"#CCC"} />
                              </button>
                              <button
                                className="quest-delete"
                                onClick={() => deleteQuestion(question.id)}
                              >
                                <Trash color={"#CCC"} />
                              </button>
                              <NavLink
                                onClick={() => setVisible(false)}
                                to={`/edit/${id}/question/${idx + 1}`}
                              >
                                Question {idx + 1}
                              </NavLink>
                            </li>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
            <button
              className="button-transparent"
              type="button"
              onClick={addQuestion}
            >
              + Add a new question
            </button>
          </li>
          <li>
            <NavLink
              onClick={() => setVisible(false)}
              to={`/edit/${id}/finish`}
            >
              Finish screen
            </NavLink>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}
