import React, { useState, useEffect } from "react";
import { useHistory, useParams, Switch, Route } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import "./CreateEdit.scss";
import { useAuth, quizTemplate } from "../../util";
import { firestore } from "../../util/firebase";
import { Loader } from "../common/index";
import Toolbar from "./Toolbar.js";

import { Formik, Form, FieldArray } from "formik";
import Settings from "./Settings";
import Question from "./Question";
import Start from "./Start";
import Finish from "./Finish";
import SaveButton from "./SaveButton";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Eye } from "../../images";

export default function CreateEdit() {
  const { push } = useHistory();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();
  const [quiz, setQuiz] = useState({});
  const [savingState, setSavingState] = useState({
    label: "Save",
    status: "save"
  });
  const urlParam = encodeURI(btoa(`${user.uid}-${id}`));
  let quizUrl = `https://quiz.wonderquiz.app/${urlParam}`;
  if (quiz?.link) {
    quizUrl = `https://quiz.wonderquiz.app/q/${quiz.link}`;
  }
  useEffect(() => {
    setIsLoading(true);
    if (id === "create") {
      setQuiz(quizTemplate);
      setIsLoading(false);
      // createQuiz();
    } else if (id) {
      firestore
        .collection("Users")
        .doc(user.uid)
        .collection("Quizzes")
        .doc(id)
        .onSnapshot(doc => {
          if (doc.data()) {
            setQuiz({ ...doc.data(), id: doc.id });
            setIsLoading(false);
          } else {
            push(`/`);
          }
        });
    }
  }, [id, user.uid, push]);

  const saveQuiz = values => {
    setSavingState({
      label: "Saving",
      status: "saving"
    });
    if (values.id) {
      firestore
        .collection("Users")
        .doc(user.uid)
        .collection("Quizzes")
        .doc(values.id)
        .set({ ...values })
        .then(doc => {
          setSavingState({
            label: "Saved",
            status: "saved"
          });
          setTimeout(function() {
            setSavingState({
              label: "Save",
              status: "save"
            });
          }, 1000);
        });
    } else {
      firestore
        .collection("Users")
        .doc(user.uid)
        .collection("Quizzes")
        .add({ ...values })
        .then(doc => {
          const link = createLink(doc.id);
          firestore
            .collection("Users")
            .doc(user.uid)
            .collection("Quizzes")
            .doc(doc.id)
            .update({ link: link })
            .then(() => {
              push(`/edit/${doc.id}/`);
            });
          setSavingState({
            label: "Saved",
            status: "saved"
          });
          setTimeout(function() {
            setSavingState({
              label: "Save",
              status: "save"
            });
          }, 1000);
        });
    }
  };

  const createLink = docid => {
    const rnd = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5);
    const urlParam = encodeURI(btoa(`${user.uid}-${docid}`));
    const quizUrl = `https://quiz.wonderquiz.app/${urlParam}`;
    firestore
      .collection("Links")
      .doc(rnd)
      .set({ url: quizUrl, user: user.uid });
    return rnd;
  };

  const deleteQuiz = id => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Do you really want to delete this quiz.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            firestore
              .collection("Users")
              .doc(user.uid)
              .collection("Quizzes")
              .doc(id)
              .delete()
              .then(doc => {
                push("/");
              })
              .catch(error => {
                console.log(error);
              });
          }
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    });
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div id="CreateEdit" className="toolbar-grid">
      <Formik
        onSubmit={values => {
          saveQuiz(values);
        }}
        initialValues={{ ...quiz }}
      >
        <Form
          onChange={() =>
            setSavingState({ label: "Save changes", status: "save" })
          }
        >
          <Toolbar />

          <div className="content">
            <div className="button-group">
              <a
                rel="noopener noreferrer"
                className="button button-preview"
                href={quizUrl}
                target="_blank"
              >
                <Eye />
                Open
              </a>
              <SaveButton state={savingState} saveQuiz={saveQuiz} />
            </div>

            <Switch>
              <Route
                path="/edit/:id/settings"
                render={props => (
                  <Settings {...props} quiz={quiz} deleteQuiz={deleteQuiz} />
                )}
              />
              <Route path="/edit/:id/start" component={Start} />
              <Route path="/edit/:id/finish" component={Finish} />
              <Route
                path="/edit/:id/question/:index"
                render={() => <Question data={quiz.questions} />}
              />
              <FieldArray name="questions">
                <Route
                  path="/edit/:id"
                  render={props => (
                    <Settings {...props} quiz={quiz} deleteQuiz={deleteQuiz} />
                  )}
                />
              </FieldArray>
            </Switch>
          </div>
        </Form>
      </Formik>
    </div>
  );
}
