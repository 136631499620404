import React from "react";
import { Field } from "formik";

export default function Finish() {
  return (
    <div>
      <header>
        <h1>Finish screen</h1>
      </header>
      <div className="box p3">
        <h3>Edit finish screen</h3>
        <div className="formgrid">
          <div className="formgroup span2">
            <label htmlFor="finish.title">Finish title</label>
            <Field
              id="finish.title"
              className="big"
              name="finish.title"
              type="text"
            />
          </div>
          <div className="formgroup span2">
            <label htmlFor="finish.text">Finish text</label>
            <Field
              rows="6"
              id="finish.text"
              name="finish.text"
              component="textarea"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
