import React from 'react';

const Arrow = ({ color }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="23.906"
		height="13.438"
		viewBox="0 0 23.906 13.438"
	>
		<defs></defs>
		<g transform="translate(-4.094 -9.281)">
			<path
				fill={color}
				class="a"
				d="M10.813,9.281,4.094,16l6.719,6.719,1.406-1.437L7.938,17H28V15H7.938l4.281-4.281Z"
			/>
		</g>
	</svg>
);

Arrow.defaultProps = {
	color: '#FFF',
};

export default Arrow;
