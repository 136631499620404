import React from "react";

const Move = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <path
      id="Icon_ionic-md-move"
      data-name="Icon ionic-md-move"
      d="M16.25,9.25l-3-3v2h-3v-3h2l-3-3-3,3h2v3h-3v-2l-3,3,3,3v-2h3v3h-2l3,3,3-3h-2v-3h3v2Z"
      transform="translate(-2.25 -2.25)"
      fill={color}
    />
  </svg>
);

Move.defaultProps = {
  color: "#FFF"
};

export default Move;
