import React from "react";

export default function Imprint() {
  return (
    <div id="TextContent" className="contained">
      <h1>Imprint</h1>
      <p>
        <strong>Responsible for this website:</strong>
        <br />
        Magnus Westhofen
        <br />
        Turner Str. 41
        <br />
        42699 Solingen
        <br />
        Germany
      </p>
    </div>
  );
}
