import React from "react";
import { Route, Switch } from "react-router-dom";
import { useAuth } from "../../util";
import "../../util/i18n";
import {
  Header,
  Content,
  Login,
  SignUp,
  Dashboard,
  CreateEdit,
  Splash,
  StartHeader,
  Privacy,
  TOS,
  Imprint,
  Forgot,
  Settings,
} from "../../components";
import { Footer } from "../common";

import "./App.scss";

function App() {
  const { user } = useAuth();
  const PublicApp = () => {
    return (
      <React.Fragment>
        <StartHeader />

        <Content>
          <Switch>
            <Route path="/signup" component={SignUp}></Route>
            <Route path="/login" component={Login}></Route>
            <Route path="/privacy" component={Privacy}></Route>
            <Route path="/terms" component={TOS}></Route>
            <Route path="/imprint" component={Imprint}></Route>
            <Route path="/forgot" component={Forgot}></Route>

            <Route path="/" component={Splash}></Route>
          </Switch>
        </Content>
        <Footer />
      </React.Fragment>
    );
  };
  const ProtectedApp = () => {
    return (
      <React.Fragment>
        <Header />
        <Content>
          <Switch>
            <Route path="/privacy" component={Privacy}></Route>
            <Route path="/terms" component={TOS}></Route>
            <Route path="/settings" component={Settings}></Route>

            <Route path="/edit/:id" component={CreateEdit}></Route>
            <Route path="/imprint" component={Imprint}></Route>

            <Route path="/" component={Dashboard}></Route>
          </Switch>
        </Content>
        <Footer />
      </React.Fragment>
    );
  };
  return <div className="App">{user ? <ProtectedApp /> : <PublicApp />}</div>;
}

export default App;
