import React, { useState, useRef } from "react";
import { useParams, Redirect } from "react-router-dom";
import { useField, Field, FieldArray } from "formik";
import FileUploader from "react-firebase-file-uploader";
import { bucket } from "../../util/firebase";
import { useAuth } from "../../util/authContext";
import { Remove, Upload, Move, Trash } from "../../images";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export default function Question({ data }) {
  const { user } = useAuth();
  const [progress, setProgress] = useState(0);
  const { id, index } = useParams();
  const uploadRef = useRef(null);
  const [answers] = useField(`questions[${index - 1}].answers`);
  const [idField] = useField(`questions[${index - 1}].id`);
  const rnd = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(0, 5);
  const [field, meta, helpers] = useField(`questions[${index - 1}].image`);
  const questionRef = `questions[${index - 1}]`;
  const bucketRef = bucket.ref(`${user.uid}/${id}`);
  const updateLogo = (response) => {
    setProgress(0);
    bucket
      .ref(`${user.uid}/${id}/${response}`)
      .getDownloadURL()
      .then((url) => {
        helpers.setValue(url);
      });
  };
  if (!idField.value) {
    return <Redirect to={`/edit/${id}/`} />;
  }

  return (
    <div>
      <header>
        <h1>Question {index}</h1>
      </header>
      <div className="box p3">
        <h3>Edit question</h3>

        <div className="formgrid">
          <div className="formgroup span2">
            <label htmlFor="start.title">Question</label>
            <Field
              className="big"
              name={`${questionRef}.question`}
              type="text"
            />
          </div>
          <div className="answers formgroup span2">
            <FieldArray
              name={`${questionRef}.answers`}
              render={(arrayHelpers) => (
                <div>
                  <div className="answer-row head">
                    <div>Answers</div>
                    <div style={{ width: "80%" }}></div>
                    <div>Correct</div>
                    <div>Remove</div>
                  </div>
                  <DragDropContext
                    onDragEnd={(data) => {
                      if (data.destination) {
                        arrayHelpers.move(
                          data.source.index,
                          data.destination.index
                        );
                      }
                    }}
                  >
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {answers.value &&
                            answers.value.map((answer, idx) => (
                              <Draggable
                                key={answer.id}
                                draggableId={answer.id}
                                index={idx}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    className="answer-row"
                                    style={{ width: "100%" }}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                  >
                                    <div {...provided.dragHandleProps}>
                                      <Move color={"#CCC"} />
                                    </div>
                                    <div>
                                      <Field
                                        name={`${questionRef}.answers[${idx}].text`}
                                        type="text"
                                      />
                                    </div>
                                    <div>
                                      <Field
                                        value={answer.id}
                                        name={`${questionRef}.correct_answer`}
                                        type="radio"
                                      />
                                    </div>
                                    <div>
                                      <button
                                        className="remove-answer"
                                        type="button"
                                        onClick={() => {
                                          arrayHelpers.remove(idx);
                                        }}
                                      >
                                        <Remove color={"#8C8F95"} />
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <button
                    className="button-transparent add-answer"
                    type="button"
                    onClick={() => arrayHelpers.push({ text: "", id: rnd })} // insert an empty string at a position
                  >
                    + Add an answer
                  </button>
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <div className="box p3">
        <h3>Explanation</h3>
        <div className="formgrid">
          <div className="formgroup">
            <label htmlFor={`${questionRef}.explanation`}>
              Question explanation
            </label>
            <Field
              rows="6"
              id={`${questionRef}.explanation`}
              name={`${questionRef}.explanation`}
              component="textarea"
            />
          </div>
          <div className="formgroup" style={{ gridRow: "span 2" }}>
            <label htmlFor="start.logo">Explanation image</label>

            <label
              className="uploader"
              style={{ backgroundImage: `url(${field.value})` }}
            >
              {!field.value && (
                <div className="image-placerholder">
                  <Upload color={"#CCC"}></Upload> Upload your image
                </div>
              )}
              {field.value && (
                <div
                  className="trash-image"
                  onClick={() => helpers.setValue("")}
                >
                  <Trash />
                </div>
              )}
              <FileUploader
                hidden
                accept="image/*"
                name="avatar"
                randomizeFilename
                ref={uploadRef}
                className={field.value ? `uploaded` : "none"}
                storageRef={bucketRef}
                onUploadError={() => alert("There was an error")}
                onUploadSuccess={updateLogo}
                onProgress={(progress) => setProgress(progress)}
              />
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </label>
            <Field
              id={`${questionRef}.image`}
              name={`${questionRef}.image`}
              type="hidden"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
