import React from "react";

const Remove = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      id="Icon_ionic-ios-remove-circle"
      data-name="Icon ionic-ios-remove-circle"
      d="M11.375,3.375a8,8,0,1,0,8,8A8,8,0,0,0,11.375,3.375Zm3.481,8.615H7.894a.6.6,0,0,1-.615-.615.589.589,0,0,1,.615-.615h6.962a.615.615,0,0,1,0,1.231Z"
      transform="translate(-3.375 -3.375)"
      fill={color}
    />
  </svg>
);

Remove.defaultProps = {
  color: "#FFF"
};

export default Remove;
