import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Dashboard.scss";
import { useAuth } from "../../util";
import { firestore } from "../../util/firebase";
import { Loader } from "../common/index";
import { Edit } from "../../images";

export default function Dashboard() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();
  const [quizzes, setQuizzes] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    let quizArray = [];
    firestore
      .collection("Users")
      .doc(user.uid)
      .collection("Quizzes")
      .onSnapshot((snapshot) => {
        snapshot.forEach((doc) => {
          quizArray.push({ ...doc.data(), id: doc.id });
        });
        setQuizzes(quizArray);
        setIsLoading(false);
      });
  }, [user.uid]);
  return (
    <div id="Dashboard" className="contained">
      <header>
        <h1>Your quizzes</h1>
        <Link to="/edit/create" className="button button-primary">
          Create Quiz
        </Link>
      </header>

      <div className="dashboard-grid">
        {quizzes.length ? (
          quizzes.map((quiz, idx) => {
            return (
              <Link key={quiz.id} to={`/edit/${quiz.id}`} className="box p2">
                {quiz.start.logo && (
                  <img src={quiz.start.logo} alt="quiz-logo" />
                )}
                <div>
                  <div className="name">{quiz.name}</div>
                  <div className="sub">{quiz.questions.length} Questions</div>
                </div>
              </Link>
            );
          })
        ) : (
          <div>
            You haven't created any quizzes.
            <br />
            <Link to={"/edit/create"}>Create one now</Link>
          </div>
        )}
      </div>
    </div>
  );
}
