import React from "react";

const Copy = ({ color, onClick }) => (
  <svg
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="16"
    viewBox="0 0 14 16"
  >
    <path
      id="Icon_awesome-copy"
      data-name="Icon awesome-copy"
      d="M10,14v1.25a.75.75,0,0,1-.75.75H.75A.75.75,0,0,1,0,15.25V3.75A.75.75,0,0,1,.75,3H3v9.25A1.752,1.752,0,0,0,4.75,14ZM10,3.25V0H4.75A.75.75,0,0,0,4,.75v11.5a.75.75,0,0,0,.75.75h8.5a.75.75,0,0,0,.75-.75V4H10.75A.752.752,0,0,1,10,3.25Zm3.78-.97L11.72.22a.75.75,0,0,0-.53-.22H11V3h3V2.811a.75.75,0,0,0-.22-.53Z"
      fill={color}
    />
  </svg>
);

Copy.defaultProps = {
  color: "#FFF"
};

export default Copy;
