import React from "react";
import { Formik, Field, Form } from "formik";
import { useAuth } from "../../util/authContext";
import { Link } from "react-router-dom";
import { FB } from "../../images";
import "./Login.scss";
import { useTranslation } from "react-i18next";

export default function Login() {
  const { signIn, signInWithGoogle, signInWithFacebook, authError } = useAuth();
  const { t } = useTranslation();
  return (
    <div id="Login">
      <h1>Login</h1>
      <Formik
        onSubmit={(values) => signIn(values)}
        initialValues={{ email: "", password: "" }}
      >
        <Form>
          <div className="formgroup">
            <label>E-Mail</label>
            <Field type="email" name="email" />
          </div>
          <div className="formgroup">
            <label>{t("login.password")}</label>
            <Field type="password" name="password" autoComplete="true" />
          </div>
          <Link className="forgot" to="/forgot">
            {t("login.forgot")}
          </Link>
          <input
            type="submit"
            className="button button-primary"
            value="Log In"
          />
        </Form>
      </Formik>

      {authError && <div className="error">{authError}</div>}
      <span className="or">{t("login.or")}</span>
      <button
        type="button"
        className="button button-google"
        onClick={signInWithGoogle}
      >
        <img
          alt=""
          src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
        />
        <span>{t("login.google")}</span>
      </button>
      <button
        type="button"
        className="button button-facebook"
        onClick={signInWithFacebook}
      >
        <img alt="" src={FB} />
        <span>{t("login.facebook")}</span>
      </button>
    </div>
  );
}
