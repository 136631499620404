import React from "react";
import { useAuth } from "../../util/authContext";
import "./Settings.scss";
import { Formik, Form, Field } from "formik";
import { confirmAlert } from "react-confirm-alert"; // Import

export default function Settings() {
  const { changePassword, deleteUser, authError, success } = useAuth();

  const confirmDelete = () => {
    confirmAlert({
      title: "Confirm to delete",
      message:
        "Do you really want to delete this account and all connected data.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteUser();
          }
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    });
  };

  return (
    <div id="Settings" className="contained">
      <h1>Settings</h1>
      <div className="settings-grid">
        <div className="box p3">
          <h3>Change password</h3>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{ password: "", repeat: "" }}
            onSubmit={values => changePassword(values)}
          >
            <Form>
              <label>New password:</label>
              <Field type="password" name="password" autoComplete="off" />

              <label>Repeat new password:</label>
              <Field type="password" name="repeat" autoComplete="off" />
              {authError && <div className="error">{authError}</div>}
              {success && <div>{success}</div>}

              <button type="submit" className="button button-primary">
                Change password
              </button>
            </Form>
          </Formik>
        </div>
        <div className="box p3">
          <h3>Delete account</h3>
          <p>You can delete your account and all connected data here.</p>
          <button
            className="button button-danger"
            onClick={() => confirmDelete()}
          >
            Delete account
          </button>
        </div>
      </div>
    </div>
  );
}
