import React from "react";

const Trash = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="12"
    viewBox="0 0 10 12"
  >
    <path
      id="Icon_ionic-md-trash"
      data-name="Icon ionic-md-trash"
      d="M7.75,15.17A1.336,1.336,0,0,0,9.083,16.5h5.333a1.336,1.336,0,0,0,1.333-1.33V7.5h-8Zm9-9.67h-2.5l-.837-1H10.087l-.837,1H6.75v1h10Z"
      transform="translate(-6.75 -4.5)"
      fill={color}
    />
  </svg>
);

Trash.defaultProps = {
  color: "#FFF"
};

export default Trash;
