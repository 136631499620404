import React from "react";
import "./Header.scss";
import { NavLink, Link } from "react-router-dom";
import { Logo } from "../../images";
import { useAuth } from "../../util/authContext";

export default function Header() {
  const { signOut } = useAuth();
  return (
    <div className="Header">
      <Link className="logo-wrap" to="/">
        <Logo />
      </Link>
      <nav className="menu">
        <ul>
          <li>
            <NavLink className="db-link" exact to="/">
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/settings">
              Settings
            </NavLink>
          </li>
          <li>
            <NavLink exact onClick={signOut} to="/">
              Logout
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}
