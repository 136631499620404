const quizTemplate = {
  name: "",
  fontScheme: "fs_01",
  colorScheme: "cs_01",
  finish: {
    text: "Thank you for finishing this quiz.",
    title: "Finished!"
  },
  questions: [],

  start: {
    title: "Welcome!",
    text: "Welcome to this really cool quiz",
    image: ""
  }
};
export default quizTemplate;
