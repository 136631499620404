import React from "react";
import { Formik, Field, Form } from "formik";
import { useAuth } from "../../util/authContext";
import "./signup.scss";
import { FB } from "../../images";
import { useTranslation } from "react-i18next";

export default function SignUp() {
  const { t } = useTranslation();
  const { signUp, signInWithGoogle, signInWithFacebook, authError } = useAuth();
  return (
    <div id="Login">
      <h1>{t("signup.headline")}</h1>
      <Formik
        onSubmit={(values) => signUp(values)}
        initialValues={{ email: "", password: "", repeat: "" }}
      >
        <Form>
          <div className="formgroup">
            <label>E-Mail</label>
            <Field type="email" name="email" />
          </div>
          <div className="formgroup">
            <label>{t("signup.password")}</label>
            <Field type="password" name="password" autoComplete="off" />
          </div>
          <div className="formgroup">
            <label>{t("signup.repeat")}</label>
            <Field type="password" name="repeat" autoComplete="off" />
          </div>
          <input
            type="submit"
            className="button button-primary"
            value={t("signup.create")}
          />
        </Form>
      </Formik>

      {authError && <div className="error">{authError}</div>}

      <span className="or">{t("signup.or")}</span>
      <button className="button button-google" onClick={signInWithGoogle}>
        <img
          alt=""
          src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
        />
        <span>{t("signup.google")}</span>
      </button>
      <button
        type="button"
        className="button button-facebook"
        onClick={signInWithFacebook}
      >
        <img alt="" src={FB} />
        <span>{t("signup.facebook")}</span>
      </button>
    </div>
  );
}
