import FS01 from '../images/font-schemes/fs_01.svg';
import FS02 from '../images/font-schemes/fs_02.svg';
import FS03 from '../images/font-schemes/fs_03.svg';

const fontSchemes = [
	{
		name: 'Merriweather & Lora',
		value: 'fs_01',
		image: FS01,
	},
	{
		name: 'Quicksand',
		value: 'fs_02',
		image: FS02,
	},
	{
		name: 'Nunito & Quicksand',
		value: 'fs_03',
		image: FS03,
	},
];

const colors = [
	'#000',
	'#f44336',
	'#e91e63',
	'#9c27b0',
	'#673ab7',
	'#3f51b5',
	'#2196f3',
	'#03a9f4',
	'#00bcd4',
	'#009688',
	'#4caf50',
	'#8bc34a',
	'#cddc39',
	'#ffc107',
	'#ff9800',
	'#ff5722',
	'#795548',
	'#607d8b',
];

export { fontSchemes, colors };
