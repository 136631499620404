import React from 'react';

const Close = ({ color }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20.438"
		height="20.438"
		viewBox="0 0 20.438 20.438"
	>
		<defs></defs>
		<g transform="translate(-5.781 -5.781)">
			<path
				fill={color}
				d="M7.219,5.781,5.781,7.219,14.563,16,5.781,24.781l1.438,1.438L16,17.438l8.781,8.781,1.438-1.437L17.438,16l8.781-8.781L24.781,5.781,16,14.563Z"
			/>
		</g>
	</svg>
);

Close.defaultProps = {
	color: '#FFF',
};

export default Close;
