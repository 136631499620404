import React from "react";

const Upload = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="74.339"
    height="74.328"
    viewBox="0 0 74.339 74.328"
  >
    <path
      id="Icon_awesome-upload"
      data-name="Icon awesome-upload"
      d="M42.977,55.749H31.362a3.476,3.476,0,0,1-3.485-3.485V27.871H15.144A2.9,2.9,0,0,1,13.1,22.92L35.18.822a2.813,2.813,0,0,1,3.964,0l22.1,22.1A2.9,2.9,0,0,1,59.2,27.871H46.462V52.264A3.476,3.476,0,0,1,42.977,55.749Zm31.362-1.162V70.849a3.476,3.476,0,0,1-3.485,3.485H3.485A3.476,3.476,0,0,1,0,70.849V54.587A3.476,3.476,0,0,1,3.485,51.1H23.231v1.162a8.136,8.136,0,0,0,8.131,8.131H42.977a8.136,8.136,0,0,0,8.131-8.131V51.1H70.854A3.476,3.476,0,0,1,74.339,54.587Zm-18,12.777a2.9,2.9,0,1,0-2.9,2.9A2.912,2.912,0,0,0,56.335,67.364Zm9.292,0a2.9,2.9,0,1,0-2.9,2.9A2.912,2.912,0,0,0,65.627,67.364Z"
      transform="translate(0 -0.005)"
      fill={color}
    />
  </svg>
);

Upload.defaultProps = {
  color: "#FFF"
};

export default Upload;
