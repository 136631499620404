import React from "react";
import { Link } from "react-router-dom";
import "./Splash.scss";
import SplashIMG from "./Splash.svg";
import { useTranslation } from "react-i18next";

export default function Splash() {
  const { t } = useTranslation();
  return (
    <div id="Splash">
      <div className="inner">
        <img src={SplashIMG} alt="Splash" className="splashimg" />
        <h1>
          {t("splash.headline")}
          <br />
        </h1>
        <div className="text">
          {t("splash.copy")}
          <div className="btns">
            <Link className="button button-primary" to="/signup">
              {t("splash.primary")}
            </Link>
            <a
              target="blank"
              rel="noreferrer noopener"
              className="button button-secondary"
              href="https://quiz.wonderquiz.app/cFJ6MDl3Um1QcFdnbTZRek1oMUZ1RVFEM3VxMi1UbUF5RGw3Z2RwUkxGbW9FaHNPcg==/"
            >
              {t("splash.secondary")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
