import React from "react";

export default function Footer() {
  return (
    <div id="Footer">
      <span>
        Made with ❤ by{" "}
        <a
          href="https://westhofen.me"
          target="_blank"
          rel="noopener noreferrer"
        >
          westhofen.me
        </a>
      </span>
    </div>
  );
}
